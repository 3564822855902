import { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RPInvoiceFormatPicker from "../../FormControls/RPInvoiceFormatPicker";
import Grid2 from "@mui/material/Unstable_Grid2";

const Address = ({ payor }) => {
  return (
    <Box>
      <Box>{payor.addr_recipient}</Box>
      <Box>{`${[payor.addr_street1, payor.addr_street2]
        .filter((x) => !!x)
        .join(" ")}`}</Box>
      <Box>
        {`${[payor.addr_city, payor.addr_state, payor.addr_zip]
          .filter((x) => !!x)
          .join(", ")}`}
      </Box>
    </Box>
  );
};

const BillGenContent = ({
  orderId,
  api,
  refresh,
  onClose,
  currentPoNumber,
}) => {
  const [poNumber, setPoNumber] = useState(currentPoNumber);
  const [submitState, setSubmitState] = useState({
    submitting: false,
    error: "",
  });
  const setSubmitError = (msg) =>
    setSubmitState({ submitting: false, error: msg });

  const handleSubmit = () => {
    setSubmitState({ submitting: true, error: "" });
    api
      .genBill(orderId, poNumber)
      .then((resp) => {
        if (resp.status !== "ok") {
          setSubmitError(resp.message);
          return;
        }
        onClose();
        refresh();
      })
      .catch((err) => setSubmitError(err.message));
  };

  return (
    <>
      <DialogContent>
        {submitState.error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitState.error}
          </Alert>
        )}
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <Alert severity="info">
              This action will cause commissions to be re-calculated for the
              agency associated with this order. Please make sure the bill has
              actually been submitted in Daisy Bill before you mark this order
              billed.
            </Alert>
          </Grid2>
          <Grid2 xs={6}>
            <TextField
              label="Enter PO number here if you have it"
              fullWidth
              size="small"
              value={poNumber}
              onChange={(e) => setPoNumber(e.target.value)}
            />
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button disabled={submitState.submitting} onClick={handleSubmit}>
          {submitState.submitting ? "..." : "Mark as Billed"}
        </Button>
      </DialogActions>
    </>
  );
};

const GenBillDialog = ({
  open,
  onClose,
  orderId,
  api,
  refresh,
  currentPoNumber,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex" }}>
        <Box>
          <Typography variant="h5">Mark Order Billed</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton edge="end" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <BillGenContent
        orderId={orderId}
        api={api}
        refresh={refresh}
        onClose={onClose}
        currentPoNumber={currentPoNumber}
      />
    </Dialog>
  );
};

export default GenBillDialog;
