import RHFAutocomplete from "./RHFAutocomplete";

const OPTIONS = [
  { value: "direct-insurance", label: "Direct Insurance" },
  { value: "ccc", label: "Cost Containment Company" },
  { value: "other", label: "Other" },
];

const RHFOrgTypePicker = (props) => {
  return <RHFAutocomplete options={OPTIONS} {...props} />;
};

RHFOrgTypePicker.byVal = (val) => OPTIONS.find((o) => o.value === val);

export default RHFOrgTypePicker;
