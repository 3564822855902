import { useState } from "react";
import SectionHeading from "../PagePartials/SectionHeading";
import {
  Alert,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { moneyFmt } from "../../formatters";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import RPOrgTypePicker from "../FormControls/RPOrgTypePicker";
import StdFormDialog from "../PagePartials/StdFormDialog";
import { InternalLink } from "../StandardComponents/InternalLink";

const orgAddForm = [
  { name: "name", label: "Company Name", component: TextField, width: 12 },
  {
    name: "main_phone",
    label: "Main Phone Number",
    component: RPPhoneNumberInput,
    width: 4,
  },
  {
    name: "org_type",
    label: "Type",
    component: RPOrgTypePicker,
    width: 4,
  },
];

const PageOrgs = ({ pageData, api, refresh }) => {
  const [addOrgOpen, setAddOrgOpen] = useState(false);
  const handleOpenOrgForm = () => setAddOrgOpen(true);
  const handleCloseOrgForm = () => setAddOrgOpen(false);

  const [showAll, setShowAll] = useState(false);

  const filteredData = showAll
    ? pageData
    : pageData.filter(
        (po) =>
          po.billed_order_count > 0 ||
          po.total_charged > 0 ||
          po.total_collected > 0 ||
          po.billed_order_total > 0 ||
          po.total_ignored > 0
      );

  return (
    <>
      <SectionHeading
        headingLabel="Payor Organizations"
        buttonLabel="Add Organization"
        buttonOnClick={handleOpenOrgForm}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Orders</TableCell>
            <TableCell>Billed</TableCell>
            <TableCell>Collected</TableCell>
            <TableCell>Outstanding</TableCell>
            <TableCell>Writeoffs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((po) => (
            <TableRow key={po.id}>
              <TableCell>
                <InternalLink to={`/orgs/${po.id}`}>{po.name}</InternalLink>
              </TableCell>
              <TableCell>{po.billed_order_count}</TableCell>
              <TableCell>{moneyFmt(po.total_charged, false)}</TableCell>
              <TableCell>{moneyFmt(po.total_collected, false)}</TableCell>
              <TableCell>{moneyFmt(po.billed_order_total, false)}</TableCell>
              <TableCell>{moneyFmt(po.total_ignored, false)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!showAll && (
        <Alert
          severity="warning"
          sx={{ mt: 2 }}
          action={
            <Button onClick={() => setShowAll(true)}>Show All Payors</Button>
          }
        >
          Only payors with some billing activity are shown right now!
        </Alert>
      )}
      <StdFormDialog
        title="Add Organization"
        fields={orgAddForm}
        onClose={handleCloseOrgForm}
        open={!!addOrgOpen}
        submitCall={(data) => {
          return api.addOrg(data);
        }}
        onComplete={() => {
          refresh();
          handleCloseOrgForm();
        }}
      />
    </>
  );
};

export default PageOrgs;
