import { daysRelative, surgeryStr } from "../utility";
import {
  WSPatientLangAndRxLink,
  WSPatientLangAndSrvcLink,
  WSPatientWithLangLink,
  WSUserAvatar,
  WSYesNoIconAvatar,
} from "./ws-shared";
import TagIconList from "../Components/StandardComponents/TagIconList";

export const patientSection = {
  title: "Patient Team",
  key: "sec-patients",
  buckets: [
    "initial-call",
    "postponed-patients",
    "fitting-needed",
    "seven-day-call",
    "ptn-ext-offer",
    "ext-not-eligible",
    "pickup-plan",
    "pickup-date",
    "bill-in-full",
  ],
  filterBuilderFn: (data) => {
    if (!data) return null;
    const list = data.reduce((acc, cur) => {
      if (!acc[cur.guide_id])
        acc[cur.guide_id] = { count: 0, guide: cur.guide_name };
      acc[cur.guide_id].count += 1;
      return acc;
    }, {});

    return {
      opts: Object.fromEntries(
        Object.entries(list).map(([value, dat]) => {
          return [value, `(${dat.count}) ${dat.guide}`];
        })
      ),
      matchingCol: "guide_id",
    };
  },
};

export const initialCall = [
  "initial-call",
  {
    title: "Initial Call",
    description:
      "WRS has accepted the initial script for this patient. The patient needs to be contacted and this will be the first time the patient has been contacted by WRS. This is drop-ship only.",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <>
            <WSPatientWithLangLink
              eoc_id={r.eoc_id}
              name_first={r.name_first}
              name_last={r.name_last}
              lang={r.lang}
            />
          </>
        ),
      },
      {
        label: "Order Status",
        cell: (r) => r.order_status,
      },
      {
        label: "Script Type",
        cell: (r) => `${r.therapy}-${r.item_code}`.toUpperCase(),
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const postponedPatients = [
  "postponed-patients",
  {
    title: "Postponed",
    description:
      "Patients with their first order set to status of postponed and who do not yet have a unit.",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientWithLangLink
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Postponed For",
        cell: (r) => `${r.postponed_for} days`,
      },
      {
        label: "Script Type",
        cell: (r) => `${r.therapy}-${r.item_code}`.toUpperCase(),
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Channel",
        cell: (r) => r.service_channel,
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const fittingNeeded = [
  "fitting-needed",
  {
    title: "Fitting",
    description:
      "Unit has been delivered and patients need to be called and fit. This is drop-ship only.",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientWithLangLink
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Unit",
        cell: (r) => `${r.therapy}-${r.item_code}`.toUpperCase(),
      },
      {
        label: "Delivered",
        cell: (r) => r.arrived_on,
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const sevenDayCall = [
  "seven-day-call",
  {
    title: "7-Day Call",
    description:
      "These are patients who were fit in the last 18 days and have not had a 7 day call logged.",
    loadCutoff: (r) => r.service_start_days_ago >= 7,
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <WSPatientWithLangLink
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Service Starts",
        cell: (r) => daysRelative(-1 * r.service_start_days_ago),
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const patientExtensionOffer = [
  "ptn-ext-offer",
  {
    title: "Extension Call",
    description:
      "These are patients who have a script that needs an extension decision. This will NOT include patients who are not 'extendable' according to the system. This will be a mix of drop-ship and agency service channels.",
    loadCutoff: (r) => r.remaining_days <= 12,
    highlight: (r) => (r.remaining_days <= 0 ? "bgError" : null),
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientLangAndRxLink
            rx_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Channel",
        cell: (r) => r.service_channel,
      },
      {
        label: "Unit",
        cell: (r) => [r.therapy, r.item_code].join("-").toUpperCase(),
      },
      {
        label: "Remaining",
        cell: (r) => daysRelative(r.remaining_days),
      },
      {
        label: "PIC",
        cell: (r) => <WSYesNoIconAvatar val={!!r.pic_complete_on} />,
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const extNotEligible = [
  "ext-not-eligible",
  {
    title: "Ext Not Eligible",
    description:
      "List of patients who have been fit but are not currently eligible for extension.",
    loadCutoff: (r) => r.remaining_days <= 0,
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientLangAndRxLink
            rx_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Expired",
        cell: (r) => `${r.remaining_days * -1} days`,
      },
      {
        label: "No Ext Reason",
        cell: (r) => r.noext_reason,
        noWrap: true,
      },
      {
        label: "PIC",
        cell: (r) => <WSYesNoIconAvatar val={!!r.pic_complete_on} />,
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const pickupPlan = [
  "pickup-plan",
  {
    title: "Pickup Plan",
    description:
      "The patient's service has been ended and now it's time to pickup the unit. This is drop-ship only.",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientLangAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Location",
        cell: (r) => [r.addr_city, r.addr_state].filter(Boolean).join(", "),
      },
      {
        label: "Serial",
        cell: (r) =>
          `${r.serial_num} (${`${r.therapy}-${r.item_code}`.toUpperCase()})`,
      },
      {
        label: "Expired For",
        cell: (r) => `${r.remaining_days} days`,
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const billInFull = [
  "bill-in-full",
  {
    title: "Bill in Full",
    description:
      "Patients in the 'Bill in Full' criteria. These are drop-ship patients who have no unit pickup plan and are at least 60 days past their service end date.",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <WSPatientLangAndSrvcLink
            srvc_id={r.id}
            eoc_id={r.eoc_id}
            name_first={r.name_first}
            name_last={r.name_last}
            lang={r.lang}
          />
        ),
      },
      {
        label: "Serial",
        cell: (r) =>
          `${r.serial_num} (${`${r.therapy}-${r.item_code}`.toUpperCase()})`,
      },
      {
        label: "Days Past Due",
        cell: (r) => `${r.days_past_due}`,
      },
      {
        label: "Tags",
        cell: (r) => <TagIconList patient={r} />,
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];
