import { forwardRef } from "react";
import { useMachine } from "@xstate/react";
import { assign, createMachine } from "xstate";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { maybeFixLazyVal } from "../../utility";

const LoadingAdornment = ({ curState, retry, def }) => {
  if (curState.matches("loading")) {
    return <CircularProgress color="inherit" size={20} />;
  }

  if (curState.matches("error")) {
    return (
      <Tooltip title={curState.context.errorMessage}>
        <IconButton size="small" onClick={() => retry()}>
          <ErrorIcon color="error" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    );
  }

  return def;
};

const optionsLoadMachine = createMachine({
  id: "optionsLoadMachine",
  predictableActionArguments: true,
  context: {
    optList: [],
    errorMessage: "",
  },
  initial: "loading",
  states: {
    loading: {
      entry: ["makeApiCall"],
      on: {
        FAIL: { target: "error" },
        LOAD: { target: "loaded" },
      },
    },
    error: {
      entry: ["setErrorMessage"],
      on: {
        RETRY: { target: "loading" },
      },
      exit: ["clearErrorMessage"],
    },
    loaded: {
      entry: ["saveData"],
      type: "final",
    },
  },
});

const RPLoadOnRenderAutoComplete = forwardRef(
  (
    {
      optionListCall,
      onChange,
      label,
      helperText,
      error,
      clearable = false,
      ...props
    },
    ref
  ) => {
    const [current, send] = useMachine(optionsLoadMachine, {
      actions: {
        makeApiCall: () => {
          optionListCall()
            .then((response) => {
              if (response.status === "ok") {
                send({ type: "LOAD", data: response.data });
                return;
              }
              send({ type: "FAIL", message: response.message });
            })
            .catch((err) => send({ type: "FAIL", message: err.message }));
        },
        saveData: assign({ optList: (_, e) => e.data }),
        setErrorMessage: assign({ errorMessage: (_, e) => e.message }),
        clearErrorMessage: assign({ errorMessage: "" }),
      },
    });

    const retry = () => send({ type: "RETRY" });
    const newVal = maybeFixLazyVal(props.value, current.context.optList);

    return (
      <Autocomplete
        {...props}
        value={newVal}
        autoSelect
        autoHighlight
        autoComplete
        clearOnEscape
        disableClearable={!clearable}
        isOptionEqualToValue={(option, value) => {
          return value.value === option.value;
        }}
        getOptionLabel={(opt) => opt.label || ""} // prevent error on first render before opt list is loaded
        onChange={(_, newVal) => {
          const val = newVal?.value;
          undefined === val ? onChange(null) : onChange(val);
        }}
        loading={current.matches("loading")}
        options={current.context.optList}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={!!error}
            helperText={helperText}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <LoadingAdornment
                  curState={current}
                  retry={retry}
                  def={params.InputProps.endAdornment}
                />
              ),
            }}
          />
        )}
      />
    );
  }
);

export default RPLoadOnRenderAutoComplete;
