import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { InternalLink } from "../../StandardComponents/InternalLink";

/**
 * @param {number} eocId
 * @param {Array<{ id: number, name: string }>} payorList
 * @constructor
 */
const PayorListCard = ({ payorList }) => {
  return (
    <Paper sx={{ p: 0 }} variant="outlined">
      <Typography sx={{ px: 2, py: 1 }}>Associated Payors</Typography>
      <Divider />
      <List disablePadding sx={{ p: 0, m: 0 }}>
        {payorList.map((payor, i) => {
          return (
            <ListItem key={payor.id} disablePadding sx={{ p: 0, m: 0 }}>
              <ListItemButton
                sx={{ px: 2, py: "2px" }}
                component={InternalLink}
                to={`/orgs/${payor.id}`}
              >
                {i === 0 ? (
                  <Tooltip
                    arrow
                    title={`This the first payor or "insurance carrier" of the claim. This is information available to reps. The other payors on this list are _not_ visible to reps via central.`}
                  >
                    <ListItemIcon>
                      <CircleIcon color="primary" fontSize="smaller" />
                    </ListItemIcon>
                  </Tooltip>
                ) : (
                  <ListItemIcon>
                    <CircleIcon fontSize="smaller" />
                  </ListItemIcon>
                )}
                <ListItemText primary={payor.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default PayorListCard;
