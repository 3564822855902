import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { InternalLink } from "../../StandardComponents/InternalLink";
import SnoozeControl from "./SnoozeControl";
import { useState } from "react";
import { apiCallSimplify } from "../../../utility";
import StandardDialog from "../../StandardComponents/StandardDialog";
import { DatePicker } from "@mui/x-date-pickers-pro";

const OrderTable = ({ api, orders, snoozed = false, visible, onChange }) => {
  const [submitState, setSubmitState] = useState("init");
  const [openCustom, setOpenCustom] = useState(null);
  const [customPick, setCustomPick] = useState(null);

  const handleSnooze = async (orderId, date) => {
    if (submitState !== "init") return;

    setSubmitState("submitting");
    try {
      await apiCallSimplify(api.snoozeUntil(orderId, date, ""));
      onChange();
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitState("init");
    }
  };

  const handleSnoozeRequest = async (orderId, date) => {
    if (null === date) {
      setOpenCustom(orderId);
      return;
    }

    await handleSnooze(orderId, date);
  };

  const reordered = [...orders].sort(
    (a, b) => new Date(a.snooze_until) - new Date(b.snooze_until)
  );

  return (
    <>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ display: visible ? "block" : "none" }}
      >
        <Table
          size="small"
          sx={{
            "& td": { paddingY: 0 },
            "& tr:last-child td": { border: 0 },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Generation</TableCell>
              <TableCell>{snoozed ? "Snoozed Until" : "Accepted"}</TableCell>
              <TableCell>Status (prev -> current)</TableCell>
              <TableCell>Stat Changed</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reordered.map((order) => {
              const orderId = `ORD-${order.ord_id.toString().padStart(4, "0")}`;
              const orderLink = `/orders/${order.ord_id}`;
              const patientLink = `/patients/${order.eoc_id}`;
              const name = [order.name_first, order.name_last].join(" ");
              const acceptedOn = format(
                new Date(order.ord_created_on),
                "MMM dd"
              );
              const snoozedUntil = format(
                new Date(order.snooze_until),
                "MMM dd"
              );
              const generation =
                order.generation === 0
                  ? "Initial"
                  : order.generation === 1
                  ? "Extension"
                  : `Extension ${order.generation}`;

              const statusText =
                order.status_prev !== null
                  ? `${order.status_prev} -> ${order.status}`
                  : order.status;

              const statusChangedOn = format(
                new Date(order.last_status_change),
                "MMM dd"
              );

              return (
                <TableRow key={order.ord_id}>
                  <TableCell>
                    <InternalLink to={orderLink}>{orderId}</InternalLink>
                  </TableCell>
                  <TableCell>
                    <InternalLink to={patientLink}>{name}</InternalLink>
                  </TableCell>
                  <TableCell>{generation}</TableCell>
                  <TableCell>
                    {order.snoozed ? snoozedUntil : acceptedOn}
                  </TableCell>
                  <TableCell>{statusText}</TableCell>
                  <TableCell>{statusChangedOn}</TableCell>
                  <TableCell>
                    <SnoozeControl
                      snoozed={order.snoozed}
                      snooze_until={order.snooze_until}
                      orderId={order.ord_id}
                      disabled={submitState !== "init"}
                      onSnoozeRequest={handleSnoozeRequest}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StandardDialog
        open={!!openCustom}
        onClose={() => setOpenCustom(null)}
        title="Snooze Until"
        actions={
          <Button
            onClick={() => {
              handleSnooze(openCustom, customPick);
              setOpenCustom(null);
              setCustomPick(null);
            }}
          >
            Snooze
          </Button>
        }
      >
        {openCustom && (
          <DatePicker
            slotProps={{
              textField: { size: "small", sx: { minWidth: "350px" } },
            }}
            value={customPick}
            onChange={setCustomPick}
          />
        )}
      </StandardDialog>
    </>
  );
};

export default OrderTable;
