import { useEffect, useState } from "react";
import PatientSummaryCard from "../PagePartials/PatientSummaryCard";
import { Box, Button, Divider, Paper, Tab, Tabs } from "@mui/material";
import SplitButton from "../StandardComponents/SplitButton";
import GenBillDialog from "./PageOrder/GenBillDialog";
import BillShowDialog from "./PageOrder/BillShowDialog";
import AttachmentListCard from "../PagePartials/AttachmentListCard";
import useDialogState from "../../HOC/useDialogState";
import PayorPickerFormDialog from "../PagePartials/PayorPickerFormDialog";
import OrderTabMain from "./PageOrder/OrderTabMain";
import OrderTabDemos from "./PageOrder/OrderTabDemos";
import OrderTabLines from "./PageOrder/OrderTabLines";
import OrderStatusChangeDialog from "./PageOrder/OrderStatusChangeDialog";
import CommentFeed from "../StandardComponents/CommentFeed";
import OrderTabAllocations from "./PageOrder/OrderTabAllocations";
import SnoozeControl from "./PageRCMDashUser/SnoozeControl";
import { apiCallSimplify } from "../../utility";
import StandardDialog from "../StandardComponents/StandardDialog";
import { DatePicker } from "@mui/x-date-pickers-pro";

const arrangePayorHistory = (pageData) => {
  const xform = pageData.payorHistory.map((phi) => ({
    orgId: phi.org_id,
    orgName: phi.org_name,
    payorId: phi.payor_id,
    payorName: phi.payor_name,
    payorType: phi.payor_type,
    onDate: phi.on_date,
  }));

  xform.sort((a, b) => +new Date(b.onDate) - +new Date(a.onDate));

  return xform;
};

const TABS_VALID = ["main", "demos", "lines", "allocs"];

const getInitialTab = () => {
  const savedTab = localStorage.getItem("order-tab");
  if (TABS_VALID.includes(savedTab)) return savedTab;
  localStorage.setItem("order-tab", TABS_VALID[0]);
  return TABS_VALID[0];
};

const PageOrder = ({ pageData, api, refresh }) => {
  const [activeTab, setActiveTab] = useState(getInitialTab());
  const handleTabChange = (e, newVal) => setActiveTab(newVal);
  const [snoozeSubmitState, setSnoozeSubmitState] = useState("init");
  const [openCustomSnooze, setOpenCustomSnooze] = useState(null);
  const [customSnoozePick, setCustomSnoozePick] = useState(null);

  const handleSnooze = async (orderId, date) => {
    if (snoozeSubmitState !== "init") return;

    setSnoozeSubmitState("submitting");
    try {
      await apiCallSimplify(api.snoozeUntil(orderId, date, ""));
      refresh();
    } catch (err) {
      console.error(err);
    } finally {
      setSnoozeSubmitState("init");
    }
  };

  const handleSnoozeRequest = async (orderId, date) => {
    if (null === date) {
      setOpenCustomSnooze(orderId);
      return;
    }

    await handleSnooze(orderId, date);
  };

  useEffect(() => {
    localStorage.setItem("order-tab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    console.log("PageOrder pageData", pageData);
  }, [pageData]);

  const {
    open: openSetStatus,
    openFn: handleOpenSetStatus,
    closeFn: handleCloseSetStatus,
  } = useDialogState();

  const {
    open: openPayor,
    openFn: handleOpenPayor,
    closeFn: handleClosePayor,
  } = useDialogState();

  const [openGenBill, setOpenGenBill] = useState(false);
  const handleOpenGenBill = () => setOpenGenBill(true);
  const handleCloseGenBill = () => setOpenGenBill(false);

  const [openShowBill, setOpenShowBill] = useState(null);
  const handleOpenShowBill = (billId) => setOpenShowBill(billId);
  const handleCloseShowBill = () => setOpenShowBill(null);

  const btnOpts = {};
  btnOpts["Change Status"] = handleOpenSetStatus;
  btnOpts["Update Payor"] = handleOpenPayor;
  btnOpts["Generate Bill"] = handleOpenGenBill;

  const btnEl = <SplitButton optsAndClicks={btnOpts} />;

  return (
    <>
      <PatientSummaryCard
        navCtx={pageData.nav}
        actionArea={
          <>
            <SnoozeControl
              iconSize="medium"
              snoozed={pageData.snoozed}
              snooze_until={pageData.snooze_until}
              orderId={pageData.id}
              disabled={snoozeSubmitState !== "init"}
              onSnoozeRequest={handleSnoozeRequest}
            />
            {btnEl}
          </>
        }
      />

      <Paper variant="outlined" sx={{ mt: 2, px: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab index={0} value="main" label="Authorization" />
          <Tab index={1} value="demos" label="Patient Demos" />
          <Tab index={2} value="lines" label="Order Lines / Invoices" />
          <Tab
            index={3}
            value="allocs"
            label="Payments"
            disabled={!pageData.allocs.length}
          />
        </Tabs>
      </Paper>

      <Box sx={{ mt: 2 }} />
      <OrderTabMain
        activeTab={activeTab}
        payorHistory={arrangePayorHistory(pageData)}
        payorList={pageData.eoc.payorList}
        api={api}
        order_id={pageData.id}
        refresh={refresh}
        status={pageData.status}
        auth_duration={pageData.auth_duration}
        po_number={pageData.purchase_order}
        statusHistory={pageData.statusHistory}
        pic_att_id={pageData.treatment.pic_att_id}
        extending_ok={pageData.extending_ok}
      />
      <OrderTabDemos
        activeTab={activeTab}
        eoc={pageData.eoc}
        rx={pageData.rx}
        rxer={pageData.rxer}
        contacts={pageData.contacts}
        api={api}
      />
      <OrderTabLines
        activeTab={activeTab}
        orderId={pageData.id}
        api={api}
        billList={pageData.bills}
        showBillFn={handleOpenShowBill}
      />
      <OrderTabAllocations
        activeTab={activeTab}
        allocations={pageData.allocs}
      />
      <Divider sx={{ my: 3 }} />
      <AttachmentListCard
        editable
        attachmentData={pageData.attachments}
        refresh={refresh}
        api={api}
        targetEntity="orders"
        targetId={pageData.id}
      />

      <CommentFeed api={api} type="treatment" id={pageData.treatment.id} />

      <PayorPickerFormDialog
        api={api}
        orderId={pageData.id}
        open={openPayor}
        onClose={handleClosePayor}
        onSuccess={() => {
          refresh();
        }}
      />
      <GenBillDialog
        open={openGenBill}
        onClose={handleCloseGenBill}
        orderId={pageData.id}
        api={api}
        refresh={refresh}
        currentPoNumber={pageData.purchase_order}
      />
      <BillShowDialog
        open={!!openShowBill}
        onClose={handleCloseShowBill}
        api={api}
        billId={openShowBill}
      />
      <OrderStatusChangeDialog
        open={openSetStatus}
        onClose={handleCloseSetStatus}
        api={api}
        orderId={pageData.id}
        refresh={refresh}
        initialVals={{
          status: pageData.status,
          auth_duration: pageData.auth_duration,
          memo: pageData.status_memo,
        }}
      />
      <StandardDialog
        open={!!openCustomSnooze}
        onClose={() => {
          setCustomSnoozePick(null);
          setOpenCustomSnooze(null);
        }}
        title="Snooze Until"
        actions={
          <Button
            onClick={() => {
              handleSnooze(openCustomSnooze, customSnoozePick);
              setOpenCustomSnooze(null);
              setCustomSnoozePick(null);
            }}
          >
            Snooze
          </Button>
        }
      >
        {openCustomSnooze && (
          <DatePicker
            slotProps={{
              textField: { size: "small", sx: { minWidth: "350px" } },
            }}
            value={customSnoozePick}
            onChange={setCustomSnoozePick}
          />
        )}
      </StandardDialog>
    </>
  );
};

export default PageOrder;
