import { useState } from "react";
import SectionHeading from "../PagePartials/SectionHeading";
import StdFormDialog from "../PagePartials/StdFormDialog";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RPDateInput from "../FormControls/RPDateInput";
import RPCurrencyInput from "../FormControls/RPCurrencyInput";
import { dateStrHyphenToDateObj, parseIntB, reformatDate } from "../../utility";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import PaymentsTable from "./PagePayments/PaymentsTable";
import RPOrgPicker from "../FormControls/RPOrgPicker";
import KeyValueList from "../PagePartials/KeyValueList";
import useSWR from "swr";
import { moneyFmt } from "../../formatters";

const initialRange = () => [addDays(new Date(), -7), new Date()];

const mkKey = (payorFilter, dateRange, showVoided) => {
  const payorFilterStr = payorFilter ? payorFilter.toString() : "";
  const dateRangeStart = dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "";
  const dateRangeEnd = dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "";
  return [
    payorFilterStr,
    dateRangeStart,
    dateRangeEnd,
    showVoided ? "1" : "0",
  ].join("_");
};

const keyParse = (key) => {
  const [payorFilter, dateRangeStart, dateRangeEnd, showVoided] =
    key.split("_");
  const filter = !!payorFilter ? parseIntB(payorFilter) : null;
  const dateRange = [
    !!dateRangeStart ? dateStrHyphenToDateObj(dateRangeStart) : null,
    !!dateRangeEnd ? dateStrHyphenToDateObj(dateRangeEnd) : null,
  ];
  return [filter, dateRange, showVoided === "1"];
};

const mkFetcher = (api) => {
  return async (key) => {
    const [filter, dateRange, showVoided] = keyParse(key);
    const callParams = Object.fromEntries(
      Object.entries({
        payor: filter ?? undefined,
        dateStart: dateRange[0]
          ? format(dateRange[0], "yyyy-MM-dd")
          : undefined,
        dateEnd: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : undefined,
        showVoided: showVoided ? "1" : undefined,
      }).filter(([, v]) => v !== undefined)
    );
    const result = await api.getPayments(callParams);

    if (result.status === "ok") return result.data;
    return result;
  };
};

const PagePayments = ({ api }) => {
  const navigate = useNavigate();

  const [payorFilter, setPayorFilter] = useState(null);
  const [dateRange, setDateRange] = useState(initialRange());
  const [showVoided, setShowVoided] = useState(false);

  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const handleOpenPaymentForm = () => setOpenPaymentForm(true);
  const handleClosePaymentForm = () => setOpenPaymentForm(false);

  const newPaymentFormFields = [
    {
      type: "plain",
      component: Typography,
      width: 3,
      extraProps: { children: "Source", variant: "h6" },
    },
    {
      name: "payor_id",
      component: RPOrgPicker,
      label: "Payor",
      width: 6,
      extraProps: { api },
    },
    {
      type: "plain",
      disableGrid: true,
      component: Grid,
      extraProps: { xs: 3 },
    },
    {
      type: "plain",
      component: Divider,
      extraProps: { sx: { mb: 3 } },
    },
    {
      type: "plain",
      component: Typography,
      width: 3,
      extraProps: { children: "Details", variant: "h6" },
    },
    {
      name: "ident",
      component: TextField,
      label: "Check Number",
      width: 4,
    },
    {
      name: "total",
      component: RPCurrencyInput,
      label: "Amount",
      width: 2,
    },
    {
      type: "plain",
      disableGrid: true,
      component: Grid,
      extraProps: { xs: 3 },
    },
    {
      type: "plain",
      disableGrid: true,
      component: Grid,
      extraProps: { xs: 3 },
    },
    {
      name: "issued_on",
      component: RPDateInput,
      label: "Entered Date",
      width: 3,
    },
    {
      name: "cleared_on",
      component: RPDateInput,
      label: "Lockbox Date",
      width: 3,
    },
  ];

  const shortCutItems = [
    {
      label: "Last 7 Days",
      getValue: () => {
        const now = new Date();
        return [addDays(now, -7), now];
      },
    },
    {
      label: "This Week",
      getValue: () => {
        const now = new Date();
        return [startOfWeek(now), endOfWeek(now)];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const now = new Date();
        const lastWeek = addWeeks(now, -1);
        return [startOfWeek(lastWeek), endOfWeek(lastWeek)];
      },
    },
    {
      label: "This Month",
      getValue: () => {
        const now = new Date();
        return [startOfMonth(now), endOfMonth(now)];
      },
    },
    {
      label: "Last Month",
      getValue: () => {
        const now = new Date();
        const lastMonth = addMonths(now, -1);
        return [startOfMonth(lastMonth), endOfMonth(lastMonth)];
      },
    },
    {
      label: "Remove Filter",
      getValue: () => [null, null],
    },
  ];

  const key = mkKey(payorFilter, dateRange, showVoided);
  const { data, isLoading, error } = useSWR(key, mkFetcher(api));

  const ndat = !!data ? data : [];

  const paymentCount = ndat.length;
  const paymentTotal = ndat.reduce((acc, cur) => acc + cur.total, 0);

  return (
    <>
      <SectionHeading
        headingLabel="Payments"
        buttonLabel="New Payment"
        buttonOnClick={handleOpenPaymentForm}
      />
      <Card variant="outlined">
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Box sx={{ width: "150px" }}>Lockbox Date</Box>
                <Box sx={{ width: "300px" }}>
                  <DateRangePicker
                    value={dateRange}
                    onAccept={(val) => setDateRange(val)}
                    slotProps={{
                      textField: { size: "small" },
                      shortcuts: { items: shortCutItems },
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Box sx={{ width: "150px" }}>Payor</Box>
                <Box sx={{ width: "300px" }}>
                  <RPOrgPicker
                    api={api}
                    value={payorFilter}
                    onChange={(p) => setPayorFilter(p)}
                    size="small"
                  />
                </Box>
              </Stack>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Box sx={{ width: "450px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setShowVoided(e.target.checked)}
                        value={showVoided}
                      />
                    }
                    label={"Show Voided Payments"}
                  />
                </Box>
              </Stack>
            </Stack>
            <Paper sx={{ width: "100%" }}>
              <KeyValueList
                kvData={{
                  "Total Payment Count": paymentCount,
                  "Sum of all Payments": moneyFmt(paymentTotal),
                }}
              />
            </Paper>
          </Stack>
        </CardContent>
      </Card>
      {paymentCount === 500 && (
        <Alert severity="info" sx={{ mt: 3 }}>
          The above filters encompass more than 500 records. Only showing the
          most recent 500 payments.
        </Alert>
      )}
      {!!isLoading && <Typography>Loading...</Typography>}
      {!!error && <Alert severity="error">{error.message}</Alert>}
      <PaymentsTable api={api} data={ndat} />
      <StdFormDialog
        open={openPaymentForm}
        onClose={handleClosePaymentForm}
        title="Add New Payment"
        fields={newPaymentFormFields}
        onComplete={(response) => {
          handleClosePaymentForm();
          navigate(`/payments/${response.data}`);
        }}
        submitCall={(data) => {
          const payload = {
            ...data,
            issued_on: reformatDate(data.issued_on),
            cleared_on: reformatDate(data.cleared_on),
            total: data.total && parseInt(data.total, 10),
          };

          return api.createPayment(payload);
        }}
      />
      <Box sx={{ mb: 6 }} />
    </>
  );
};

export default PagePayments;
