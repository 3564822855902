import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { moneyFmt } from "../../../formatters";
import { format } from "date-fns";

const OutstandingOrdersTable = ({ outstandingOrders }) => {
  if (outstandingOrders.length === 0) {
    return (
      <Paper sx={{ p: 2 }}>
        <Typography>No Oustanding Orders with this Payor 🎉🎉</Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2 }} variant="outlined">
      <Typography variant="h6">Outstanding Orders</Typography>
      <Divider sx={{ mb: 2 }} />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Therapy</TableCell>
              <TableCell>Billed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outstandingOrders.map((order) => {
              const patientName = [order.name_first, order.name_last].join(" ");
              const orderId = `ORD-${order.order_id
                .toString()
                .padStart(4, "0")}`;
              return (
                <TableRow key={order.order_id}>
                  <TableCell>
                    <InternalLink to={`/patients/${order.eoc_id}`}>
                      {patientName}
                    </InternalLink>
                  </TableCell>
                  <TableCell>{moneyFmt(order.balance)}</TableCell>
                  <TableCell>
                    <InternalLink to={`/orders/${order.order_id}`}>
                      {orderId}
                    </InternalLink>
                  </TableCell>
                  <TableCell>{order.therapy}</TableCell>
                  <TableCell>
                    {format(new Date(order.bill_gen_first), "MM/dd/yyyy")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OutstandingOrdersTable;
