import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { InternalLink } from "../../StandardComponents/InternalLink";
import { Box } from "@mui/material";
import { dateObjFormatToAnnArborDate } from "../../../utility";
/*
payorHistoryList = [
  {
    orgId: number,
    payorId: number|null,
    orgName: string,
    payorName: string|null,
    onDate: string
];
 */

const TimeLinePayorLabel = ({
  orgId,
  orgName,
  payorId,
  payorName,
  payorType,
}) => {
  const payorBit = payorId ? ` - ${payorName}` : null;
  return (
    <Box>
      <InternalLink to={`/payors/${orgId}`}>{orgName}</InternalLink>
      {payorBit}
    </Box>
  );
};

const PayorTimeline = ({ payorHistoryList }) => {
  const listAmt = payorHistoryList.length;

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.25,
        },
        mb: 0,
        pb: 0,
      }}
    >
      {payorHistoryList.map((phi, i) => (
        <TimelineItem key={`${phi.orgName}${phi.payorName}${phi.onDate}`}>
          <TimelineOppositeContent color="text.secondary">
            {dateObjFormatToAnnArborDate(phi.onDate)}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {i + 1 === listAmt ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent variant="body2">
            <TimeLinePayorLabel {...phi} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default PayorTimeline;
