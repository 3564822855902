import {
  Alert,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import RPOrgPicker from "../FormControls/RPOrgPicker";
import StandardDialog from "../StandardComponents/StandardDialog";
import useSWR from "swr";
import { apiCallSimplify, genericSWRFetcher } from "../../utility";
import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";

const PayorPickerFormDialog = ({ api, orderId, open, onClose, onSuccess }) => {
  const swrKey = orderId ? `orderpayors-${orderId}` : null;
  const { data, isLoading, error, mutate } = useSWR(
    swrKey,
    genericSWRFetcher(api.orderPayors, () => [orderId])
  );
  const [newPayor, setNewPayor] = useState(null);

  if (isLoading) {
    return <StandardDialog open={open} title="Loading..." />;
  }

  if (error || !data) {
    return (
      <StandardDialog open={open} title="Error">
        <Alert severity="error">{error?.message || "Unknown Error"}</Alert>
      </StandardDialog>
    );
  }

  const newPayorApiCall = async (payorId) => {
    try {
      await apiCallSimplify(api.setOrderPayor(orderId, payorId));
      mutate();
      setNewPayor(null);
      onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StandardDialog
      onClose={onClose}
      open={open}
      title="Set Responsible Payor for this Order"
    >
      <Stack direction="column" spacing={2} sx={{ px: 10 }}>
        <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
          <RPOrgPicker
            label="Pick Payor"
            api={api}
            size="small"
            orderId={orderId}
            onSuccess={onSuccess}
            onChange={(newVal) => setNewPayor(newVal)}
            fullWidth
            clearable
            value={newPayor}
          />
          <Button
            disabled={!newPayor}
            variant={!newPayor ? "text" : "contained"}
            sx={{ width: "45%" }}
            onClick={() => newPayorApiCall(newPayor)}
          >
            New Responsible Payor
          </Button>
        </Stack>
        <Divider />
        <List>
          {[...data.eocPorgs].reverse().map(({ id, name }, idx) => (
            <ListItem
              key={id}
              secondaryAction={
                id === data.payorHistory[0].org_id ? (
                  <Typography>Current Payor</Typography>
                ) : (
                  <Button onClick={() => newPayorApiCall(id)}>
                    Make Responsible
                  </Button>
                )
              }
            >
              <ListItemIcon>
                <CircleIcon
                  color={
                    idx + 1 === data.eocPorgs.length ? "primary" : "inherit"
                  }
                  fontSize="smaller"
                />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      </Stack>
    </StandardDialog>
  );
};

export default PayorPickerFormDialog;
