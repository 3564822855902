import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import StandardDialog from "../../StandardComponents/StandardDialog";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFPhoneField from "../../FormControlsRHF/RHFPhoneField";
import Grid2 from "@mui/material/Unstable_Grid2";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFOrgTypePicker from "../../FormControlsRHF/RHFOrgTypePicker";

const UpdateOrgDialog = ({
  open,
  onClose,
  orgId,
  initialVals,
  api,
  refresh,
}) => {
  const defaultValues = {
    name: initialVals.name,
    main_phone: initialVals.main_phone,
    org_type: RHFOrgTypePicker.byVal(initialVals.org_type),
  };
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm({ defaultValues });
  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onClose();
      refresh();
    }
  );

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = (data) => {
    data.org_type = data.org_type.value;
    apiWrapper(api.updateOrg(orgId, data));
  };

  return (
    <StandardDialog open={open} onClose={handleClose} title="Update Payor">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container rowSpacing={2} columnSpacing={2}>
          {formErrState.hasErrors && (
            <Grid2 xs={12}>
              <FormLevelErrorAlert formErrStruct={formErrState} />
            </Grid2>
          )}
          <Grid2 xs={12}>
            <RHFTextField control={control} name="name" label="Payor Name" />
          </Grid2>
          <Grid2 xs={4}>
            <RHFPhoneField
              control={control}
              name="main_phone"
              label="Main Phone Number"
            />
          </Grid2>
          <Grid2 xs={4}>
            <RHFOrgTypePicker control={control} name="org_type" label="Type" />
          </Grid2>
          <Grid2 xs={12} sx={{ textAlign: "right" }}>
            <Button disabled={isSubmitting} type="submit" variant="contained">
              Update Payor
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default UpdateOrgDialog;
