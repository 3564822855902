import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Alert, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useStdFormErrors from "../../HOC/useStdFormErrors";
import useDialogState from "../../HOC/useDialogState";
import OrderAllocCard from "./PagePayment/OrderAllocCard";
import PaymentDetailsBox from "./PagePayment/PaymentDetailsBox";
import PaymentEditFormDialog from "./PagePayment/PaymentEditFormDialog";
import PostDetailsBox from "./PagePayment/PostDetailsBox";
import FormLevelErrorAlert from "../StandardComponents/FormLevelErrorAlert";
import OrderSearchCard from "./PagePayment/OrderSearchCard";
import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";
import { dateObjFormatToAnnArborDateTime } from "../../utility";

const genDefaultValues = (pageData) => {
  const { allocations, orders } = pageData;
  const defaultValues = { allocations: [] };

  Object.values(orders).forEach((ord) => {
    const ordAlloc = searchAlloc(allocations, ord.order_id, null);
    defaultValues.allocations.push({
      order_id: ord.order_id,
      ol_id: null,
      balance_init: 0,
      alloc_init: ordAlloc?.alloc || 0,
      alloc: ordAlloc?.alloc || 0,
      alloc_unparsed: ((ordAlloc?.alloc || 0) / 100).toFixed(2),
    });
    ord.lines.forEach((l) => {
      const hasAlloc = searchAlloc(allocations, ord.order_id, l.order_line_id);
      const formEntry = {
        order_id: ord.order_id,
        ol_id: l.order_line_id,
        balance_init: l.balance,
        alloc_init: hasAlloc?.alloc || 0,
        alloc: hasAlloc?.alloc || 0,
        alloc_unparsed: ((hasAlloc?.alloc || 0) / 100).toFixed(2),
      };
      defaultValues.allocations.push(formEntry);
    });
  });

  return defaultValues;
};

/**
 * Utility to find an allocation from the list by orderId / orderLineId
 *
 * @param {Array<{
 *   order_id: number,
 *   ol_id: number | null,
 * }>} allocList
 * @param {number} orderId
 * @param {number|null} lineId
 */
const searchAlloc = (allocList, orderId, lineId = null) =>
  allocList.find((a) => a.order_id === orderId && a.ol_id === lineId);

const PagePayment = ({ pageData, api, refresh }) => {
  const {
    open: editPaymentOpen,
    closeFn: handleEditPaymentClose,
    openFn: handleEditPaymentOpen,
  } = useDialogState();

  const {
    open: voidPaymentOpen,
    closeFn: handleVoidPaymentClose,
    openFn: handleVoidPaymentOpen,
  } = useDialogState();

  const {
    open: arIgnoreOpen,
    closeFn: handleArIgnoreClose,
    openFn: handleArIgnoreOpen,
  } = useDialogState(false);

  const [extraOrders, setExtraOrders] = useState({});
  const addExtraOrder = (orderId, order) =>
    setExtraOrders((prev) =>
      Object.fromEntries([...Object.entries(prev), [orderId, order]])
    );
  const clearExtraOrders = () => setExtraOrders({});

  const defaultValues = useMemo(() => genDefaultValues(pageData), [pageData]);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    clearExtraOrders();
    reset(defaultValues);
  }, [pageData, reset, defaultValues]);

  const { apiWrapper, formErrState, clearFormError } = useStdFormErrors(
    setError,
    {},
    () => {
      refresh();
    }
  );

  const { fields, append } = useFieldArray({
    name: "allocations",
    control,
  });

  const onSubmit = (data) => {
    const payload = data.allocations
      .filter((a) => a.alloc !== 0)
      .map((a) => ({
        order_id: a.order_id,
        ol_id: a.ol_id,
        alloc: a.alloc,
      }));
    clearFormError();
    apiWrapper(api.allocPayment(pageData.id, payload));
  };

  const combinedOrders = [
    ...Object.entries(pageData.orders),
    ...Object.entries(extraOrders),
  ];

  const paymentVoided = pageData.total === 0;

  if (paymentVoided) {
    return (
      <Alert severity="error">
        Payment was voided by <strong>{pageData.modified_by_user_name}</strong>{" "}
        on{" "}
        <strong>
          {dateObjFormatToAnnArborDateTime(pageData.modified_on, false)}
        </strong>
      </Alert>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2} sx={{ mb: 2 }}>
          <Grid2 xs={6}>
            <PaymentDetailsBox
              ident={pageData.ident}
              org_id={pageData.org_id}
              porg_name={pageData.porg_name}
              issued={pageData.issued_on}
              cleared={pageData.cleared_on}
              total={pageData.total}
              onEditReqFn={handleEditPaymentOpen}
              onVoidClick={handleVoidPaymentOpen}
              isVoidable={combinedOrders.length === 0}
              formDirty={isDirty}
              api={api}
              paymentId={pageData.id}
            />
          </Grid2>
          <Grid2 xs={6}>
            <PostDetailsBox
              control={control}
              checkTotal={pageData.total}
              reset={reset}
              formDirty={isDirty}
            />
          </Grid2>
          {formErrState.hasErrors && (
            <Grid2 xs={12}>
              <FormLevelErrorAlert
                formErrStruct={formErrState}
                sx={{ mt: 2 }}
              />
            </Grid2>
          )}
        </Grid2>
        <Stack direction="column" spacing={2}>
          {combinedOrders.map(([order_id, order]) => {
            return (
              <OrderAllocCard
                key={`ord-${order_id}`}
                firstName={order.name_first}
                lastName={order.name_last}
                eocId={order.eoc_id}
                rxId={order.rx_id}
                orderId={order.order_id}
                therapy={order.therapy}
                generation={order.generation}
                control={control}
                lines={order.lines}
                fields={fields}
                dos={order.dos_start}
                setterFn={setValue}
                initiateARIgnoreFn={handleArIgnoreOpen}
              />
            );
          })}
        </Stack>
      </form>
      <OrderSearchCard
        api={api}
        onOrderSelect={(orderId, o) => {
          append({
            order_id: o.order_id,
            ol_id: null,
            balance_init: 0,
            alloc_init: 0,
            alloc: 0,
            alloc_unparsed: "0.00",
          });
          o.lines.forEach((l) => {
            append({
              order_id: o.order_id,
              ol_id: l.order_line_id,
              balance_init: l.balance,
              alloc_init: 0,
              alloc: 0,
              alloc_unparsed: "0.00",
            });
          });
          addExtraOrder(orderId, o);
        }}
      />
      <PaymentEditFormDialog
        api={api}
        refresh={refresh}
        open={editPaymentOpen}
        onClose={handleEditPaymentClose}
        paymentId={pageData.id}
        initialVals={pageData}
      />
      <StdConfirmSubmitDialog
        open={voidPaymentOpen}
        title="Void Payment?"
        onSubmit={() => api.voidPayment(pageData.id)}
        handleClose={handleVoidPaymentClose}
        onComplete={() => {
          refresh();
          handleVoidPaymentClose();
        }}
      />
      <StdConfirmSubmitDialog
        open={!!arIgnoreOpen}
        title="Write off this charge?"
        onSubmit={() => api.orderLineARIgnore(arIgnoreOpen)}
        handleClose={handleArIgnoreClose}
        onComplete={() => {
          handleArIgnoreClose();
          refresh();
        }}
      >
        Mark this charge as "written off"? The remaining balance will not show
        up in AR reporting.
      </StdConfirmSubmitDialog>
    </>
  );
};

export default PagePayment;
