import SectionHeading from "../PagePartials/SectionHeading";
import {
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  Stack,
  Paper,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import KeyValueList from "../PagePartials/KeyValueList";
import { moneyFmt, phoneFmt } from "../../formatters";
import SplitButton from "../StandardComponents/SplitButton";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import useDialogState from "../../HOC/useDialogState";
import UpdateOrgDialog from "./PageOrg/UpdateOrgDialog";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";
import OutstandingOrdersTable from "./PageOrg/OutstandingOrdersTable";

function formatAsPercentage(value) {
  if (isNaN(value) || !isFinite(value)) {
    return "N/A";
  }
  return (value * 100).toFixed(1) + "%";
}

const typeValToStr = (typeVal) => {
  const conv = {
    "direct-insurance": "Direct Insurance",
    ccc: "Cost Containment Company",
    other: "Other",
  };

  return conv[typeVal] || "Unknown";
};

/**
 * @param {Array<{
 *   balance: number,
 *   bill_gen_first: string,
 * }>} orders
 */
const splitOrdersToArBuckets = (orders) => {
  const arBuckets = {
    "0-30": [],
    "31-60": [],
    "61-90": [],
    "91-120": [],
    "121+": [],
  };

  orders.forEach((order) => {
    const daysOld = Math.floor(
      (new Date() - new Date(order.bill_gen_first)) / (1000 * 60 * 60 * 24)
    );

    if (daysOld <= 30) {
      arBuckets["0-30"].push(order);
      order.arBucket = 0;
    } else if (daysOld <= 60) {
      arBuckets["31-60"].push(order);
      order.arBucket = 1;
    } else if (daysOld <= 90) {
      arBuckets["61-90"].push(order);
      order.arBucket = 2;
    } else if (daysOld <= 120) {
      arBuckets["91-120"].push(order);
      order.arBucket = 3;
    } else {
      arBuckets["121+"].push(order);
      order.arBucket = 4;
    }
  });

  return arBuckets;
};

const ARStatCard = ({ label, amt }) => {
  return (
    <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
      <Typography sx={{ fontWeight: "semibold" }}>{label}</Typography>
      <Typography variant="h6">{amt}</Typography>
    </Paper>
  );
};

const PageOrg = ({ pageData, api, refresh }) => {
  const {
    open: updatePayorOpen,
    openFn: handleOpenUpdatePayor,
    closeFn: handleCloseUpdatePayor,
  } = useDialogState();

  const splitBtn = (
    <SplitButton
      optsAndClicks={{
        "Update Payor": handleOpenUpdatePayor,
      }}
    />
  );

  const arBuckets = splitOrdersToArBuckets(pageData.orders_with_balance);

  return (
    <>
      <BackLinkHeader title="Back to Payors" to="/orgs" />
      <SectionHeading
        headingLabel={pageData.name}
        buttonEl={
          <Stack spacing={1} direction="row">
            {splitBtn}
            <TicketContextMenu
              api={api}
              context={{
                type: "payor_orgs",
                ident: pageData.id,
                label: `${pageData.name}`,
              }}
            />
          </Stack>
        }
      />
      <Paper variant="outlined" sx={{ mb: 2, p: 2 }}>
        <Stack direction="row">
          <Typography>
            Company Type: <strong>{typeValToStr(pageData.org_type)}</strong>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Typography>
            Main Phone Number: <strong>{phoneFmt(pageData.main_phone)}</strong>
          </Typography>
        </Stack>
      </Paper>
      <Grid container spacing={2} columns={25}>
        <Grid xs={8}>
          <Card variant="outlined">
            <CardContent>
              <KeyValueList
                noItemYPadding
                noItemXPadding
                kvData={{
                  "Total Billed Orders": pageData.billed_order_count,
                  "Total Billed Amount": moneyFmt(
                    pageData.total_charged,
                    false
                  ),
                  "Total Collected": moneyFmt(pageData.total_collected, false),
                  "Total Receivables": moneyFmt(
                    pageData.billed_order_total,
                    false
                  ),
                  "Total Writeoffs": moneyFmt(pageData.total_ignored, false),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={8}>
          <Card variant="outlined">
            <CardContent>
              <KeyValueList
                noItemYPadding
                noItemXPadding
                kvData={{
                  "Total Patients": pageData.orderAggs.patientCount,
                  Approvals: pageData.orderAggs.approvals,
                  Denials: pageData.orderAggs.denials,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={9}>
          <Card variant="outlined">
            <CardContent>
              <KeyValueList
                noItemYPadding
                noItemXPadding
                kvData={{
                  "Collections Per Billed Order": moneyFmt(
                    pageData.total_collected / pageData.billed_order_count,
                    false
                  ),
                  "Approval Rate": formatAsPercentage(
                    pageData.orderAggs.approvalRate
                  ),
                  "Denial Rate": formatAsPercentage(
                    pageData.orderAggs.denialRate
                  ),
                  "Collection Rate": formatAsPercentage(
                    pageData.total_collected / pageData.total_charged
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={25}>
          <Typography variant="h5">Accounts Receivable</Typography>
          <Divider />
        </Grid>
        <Grid xs={5}>
          <ARStatCard
            label="0-30 Days"
            amt={moneyFmt(
              arBuckets["0-30"].reduce((acc, cur) => acc + cur.balance, 0),
              false
            )}
          />
        </Grid>
        <Grid xs={5}>
          <ARStatCard
            label="31-60 Days"
            amt={moneyFmt(
              arBuckets["31-60"].reduce((acc, cur) => acc + cur.balance, 0),
              false
            )}
          />
        </Grid>
        <Grid xs={5}>
          <ARStatCard
            label="61-90 Days"
            amt={moneyFmt(
              arBuckets["61-90"].reduce((acc, cur) => acc + cur.balance, 0),
              false
            )}
          />
        </Grid>
        <Grid xs={5}>
          <ARStatCard
            label="91-120 Days"
            amt={moneyFmt(
              arBuckets["91-120"].reduce((acc, cur) => acc + cur.balance, 0),
              false
            )}
          />
        </Grid>
        <Grid xs={5}>
          <ARStatCard
            label="121+ Days"
            amt={moneyFmt(
              arBuckets["121+"].reduce((acc, cur) => acc + cur.balance, 0),
              false
            )}
          />
        </Grid>
        <Grid xs={25}>
          <OutstandingOrdersTable
            outstandingOrders={pageData.orders_with_balance}
          />
        </Grid>
      </Grid>
      <UpdateOrgDialog
        open={updatePayorOpen}
        onClose={handleCloseUpdatePayor}
        api={api}
        refresh={refresh}
        orgId={pageData.id}
        initialVals={{
          name: pageData.name,
          main_phone: pageData.main_phone,
          org_type: pageData.org_type,
        }}
      />
    </>
  );
};

export default PageOrg;
