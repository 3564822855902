import {
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import PayorTimeline from "./PayorTimeline";
import { dateObjFormatToAnnArborDateTime } from "../../../utility";
import { useState } from "react";
import { InternalLink } from "../../StandardComponents/InternalLink";

const authDuration = (duration) => {
  if (duration === null) return "[UNKNOWN]";
  return `${duration} days`;
};

const OrderTabMain = ({
  activeTab,
  status,
  order_id,
  auth_duration,
  po_number,
  payorList,
  payorHistory,
  statusHistory,
  pic_att_id,
  extending_ok,
  api,
  refresh,
}) => {
  const [extAllowedDisable, disableExtAllowed] = useState(false);

  if (activeTab !== "main") return null;

  const extAllowedChange = async () => {
    disableExtAllowed(true);
    try {
      await api.setExtensionOk(order_id, !extending_ok);
      refresh();
    } catch (err) {
      console.error(err);
    }
    disableExtAllowed(false);
  };

  return (
    <Grid2 container columnSpacing={2} rowSpacing={2}>
      <Grid2 xs={5}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Typography sx={{ fontSize: "larger" }}>Order</Typography>
          <Divider />
          <List dense>
            <ListItem>
              <ListItemText primary="Status" />
              <ListItemSecondaryAction sx={{ typography: "body2" }}>
                {status}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Authorized For" />
              <ListItemSecondaryAction sx={{ typography: "body2" }}>
                {authDuration(auth_duration)}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Extension Allowed?" />
              <ListItemSecondaryAction sx={{ typography: "body2" }}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={extAllowedDisable}
                      onChange={extAllowedChange}
                      checked={!!extending_ok}
                    />
                  }
                  label={extending_ok ? "Yes" : "No"}
                  disableTypography
                  sx={{ p: 0, m: 0 }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Responsible Payor" />
              <ListItemSecondaryAction>
                <InternalLink
                  to={`/payors/${payorHistory[0].orgId}`}
                  sx={{ typography: "body2" }}
                >
                  {payorHistory[0].orgName}
                </InternalLink>
              </ListItemSecondaryAction>
            </ListItem>
            {po_number && (
              <ListItem>
                <ListItemText primary="PO Number" />
                <ListItemSecondaryAction>{po_number}</ListItemSecondaryAction>
              </ListItem>
            )}
            {pic_att_id && (
              <ListItem>
                <ListItemText primary="PIC" />
                <ListItemSecondaryAction>{pic_att_id}</ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid2>
      <Grid2 xs={7}>
        <Paper variant="outlined" sx={{ p: 2, pb: 0 }}>
          <Typography sx={{ fontSize: "larger" }}>Payor History</Typography>
          <Divider />
          <PayorTimeline payorHistoryList={payorHistory} />
        </Paper>
      </Grid2>
      <Grid2 xs={12}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Typography sx={{ fontSize: "larger" }}>Status History</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>On</TableCell>
                <TableCell>By</TableCell>
                <TableCell>With Memo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusHistory.map((sc) => (
                <TableRow key={sc.created_on}>
                  <TableCell>{sc.prev_status || "(unknown)"}</TableCell>
                  <TableCell>{sc.status}</TableCell>
                  <TableCell>
                    {sc.auth_duration !== null
                      ? `${sc.auth_duration} days`
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {dateObjFormatToAnnArborDateTime(sc.created_on, false)}
                  </TableCell>
                  <TableCell>{sc.changed_by}</TableCell>
                  <TableCell sx={{ whiteSpace: "pre-line" }}>
                    {sc.memo}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid2>
    </Grid2>
  );
};

export default OrderTabMain;
