import {
  Alert,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import StdUserAvatar from "../../StandardComponents/StdUserAvatar";
import { InternalLink } from "../../StandardComponents/InternalLink";

const LatestOrdersTable = ({ orders }) => {
  return (
    <Stack spacing={1}>
      {orders.length === 500 ? (
        <Alert severity="info">
          Filters are catching too many orders. Only showing the latest 500.
        </Alert>
      ) : (
        <Typography variant="h6">{orders.length} Orders</Typography>
      )}

      <TableContainer component={Paper} variant="outlined">
        <Table
          size="small"
          sx={{ paddingX: 0, "& tr:last-child td": { border: 0 } }}
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Generation</TableCell>
              <TableCell>Last Status Change</TableCell>
              <TableCell>Accepted</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Guide</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              const orderId = `ORD-${order.ord_id.toString().padStart(4, "0")}`;
              const orderLink = `/orders/${order.ord_id}`;
              const patientLink = `/patients/${order.eoc_id}`;
              const name = [order.name_first, order.name_last].join(" ");
              const acceptedOn = format(
                new Date(order.ord_created_on),
                "MMM dd"
              );
              const lastStatusChangeOn = format(
                new Date(order.last_status_change),
                "MMM dd"
              );
              const generation =
                order.generation === 0
                  ? "Initial"
                  : order.generation === 1
                  ? "Extension"
                  : `Extension ${order.generation}`;
              return (
                <TableRow key={order.ord_id}>
                  <TableCell>
                    <InternalLink to={orderLink}>{orderId}</InternalLink>
                  </TableCell>
                  <TableCell>
                    <InternalLink to={patientLink}>{name}</InternalLink>
                  </TableCell>
                  <TableCell>{generation}</TableCell>
                  <TableCell>{lastStatusChangeOn}</TableCell>
                  <TableCell>{acceptedOn}</TableCell>
                  <TableCell>{order.status}</TableCell>
                  <TableCell>
                    <StdUserAvatar
                      user_name={order.billing_guide_name}
                      user_pic={order.billing_guide_pic}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default LatestOrdersTable;
