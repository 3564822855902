import { forwardRef } from "react";
import RPAutoCompleteCore from "./RPAutoCompleteCore";

const opts = [
  { value: "direct-insurance", label: "Direct Insurance" },
  { value: "ccc", label: "Cost Containment Company" },
  { value: "other", label: "Other" },
];

const RPOrgTypePicker = forwardRef((props, ref) => {
  return <RPAutoCompleteCore {...props} ref={ref} options={opts} />;
});

RPOrgTypePicker.defaultValue = null;

export default RPOrgTypePicker;
